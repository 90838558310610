@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}



body {
  color: rgb(var(--foreground-rgb));
}

#nprogress .bar {
  background: #54f167 !important; 
}

#nprogress .peg {
  box-shadow: 0 0 10px #54f167, 0 0 5px #54f167;
}

#nprogress .spinner-icon {
  display: none;
}
